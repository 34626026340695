import React from "react"
import Img from 'gatsby-image';
import { useStaticQuery, Link, graphql } from "gatsby";
import styled, { keyframes } from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import logoSrc from "../images/biobeechoshotel-logo.svg"
import logoBee from "../images/biobeechoshotel-bee.svg"

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Container = styled.article`
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas:
    "logo logo casa casa casa"
    "text text casa casa casa"
    "text text casa casa casa"
    "text text casa casa casa"
    "foot foot foot foot foot";
  grid-gap: 0;
  overflow: hidden;

  & > * {
    padding: 2rem;
  }

  @media screen and (max-width: 850px) {
    grid-template-areas:
      "logo"
      "text"
      "casa"
      "foot";
  }
`

const Text = styled.section`
  grid-area: text;
  opacity: 0;
  animation: ${fadeIn} 500ms 1300ms ease-out;
  animation-fill-mode: forwards;
  border-left: 0;

  @media screen and (max-width: 850px) {
    max-width: 400px;
    margin: 0 auto;
  }
`
const Logo = styled(Link)`
  grid-area: logo;
  border-left: 0;
  position: relative;

  & > img {
    margin: 0;
  }

  @media screen and (max-width: 850px) {
    max-width: 100%;
    margin: 0 auto;
  }
`
const Casa = styled.div`
  grid-area: casa;
  opacity: 0;
  animation: ${fadeIn} 500ms 1400ms ease-out;
  animation-fill-mode: forwards;
`

const Footer = styled.footer`
  grid-area: foot;
  color: grey;
  font-size: 0.8rem;
  opacity: 0;
  animation: ${fadeIn} 500ms 1600ms ease-out;
  animation-fill-mode: forwards;
  border: 0;

  @media screen and (max-width: 850px) {
    text-align: center;
  }
`

const Bee = styled.img`
  position: absolute;
  left: 75px;
  top: 22px;
  opacity: 0;
  animation: ${fadeIn} 500ms 500ms ease-out;
  animation-fill-mode: forwards;
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "biobeechos-casa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960, traceSVG: { optTolerance: 1, turdSize: 1, threshold: 100 }) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Bienvenido" />
      <Container>
        <Logo to="/">
          <img
            src={logoSrc}
            alt={data.site.siteMetadata.title}
            width={144}
            height={112}
          />
          <Bee
            src={logoBee}
            alt={data.site.siteMetadata.title}
            width={104}
            height={104}
          />
        </Logo>
        <Text>
          <h1>Bienvenido!</h1>
          <h2>Que son los BioBEEchos hoteles?</h2>
          <p>
            Los BioBEEchos hoteles sirven para compensar la carencia de
            cavidades naturales, ofreciendo a los insectos agujeros
            artificiales.
          </p>
          <p>
            De esta manera, los hoteles contribuyen a la conservación del
            ecosistema y de los insectos que son actores imprescindibles dentro
            de la biodiversidad.
          </p>
        </Text>
        <Casa>
          <Img fluid={data.file.childImageSharp.fluid} />
        </Casa>
        <Footer>
          © {new Date().getFullYear()} – {data.site.siteMetadata.title}
        </Footer>
      </Container>
    </Layout>
  )
}

export default IndexPage
